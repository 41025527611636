import React from "react";
import layoutStyles from "@/styles/layout.module.scss";
import Image from "next/image";
import { useRouter } from "next/router";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { ShortUserView, UserMeView } from "@/api/generated";
import { bgConfigs } from "@/lib/dscribe";

export default function BgImage({
  user,
}: {
  user?: ShortUserView | UserMeView;
}) {
  const router = useRouter();
  const bgSmall = useMediaQuery(900);

  if (router.pathname === "/") {
    return null;
  }

  const coverImage = user?.cover_image || "coverImage01";
  if (coverImage.startsWith("#")) {
    return (
      <>
        <div
          className={layoutStyles.bgCover}
          style={{ backgroundColor: coverImage }}
        ></div>
      </>
    );
  }

  const bgConfig =
    bgConfigs.find((config) => config.id === coverImage) || bgConfigs[0];

  return (
    <>
      <div
        className={[layoutStyles.bgCover, layoutStyles.bgCoverImage].join(" ")}
      >
        <Image
          src={bgSmall ? bgConfig.src : bgConfig.srcFull}
          alt="background"
          layout="fill"
          objectFit="cover"
        />
      </div>
    </>
  );
}
