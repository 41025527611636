import React from "react";
import utilStyles from "@/styles/utils.module.scss";
import AppLogo from "../AppLogo";
import Link from "next/link";
import Layout from "@/components/Layout/Layout";
import BgImage from "@/components/BgImage";

export default function AppLayout({
  children,
}: {
  children: React.ReactNode;
  home?: boolean;
}) {
  const header = (
    <header className={[utilStyles.header, utilStyles.headerFront].join(" ")}>
      <Link href="/">
        <a>
          <AppLogo />
        </a>
      </Link>
    </header>
  );

  return (
    <Layout header={header}>
      <BgImage />
      {children}
    </Layout>
  );
}
