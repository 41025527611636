import React from "react";
import Image from "next/image";

export default function AppLogo({
  width = 120,
  height = 18,
  src = "/images/logo.svg",
}: {
  width?: number;
  height?: number;
  src?: string;
}) {
  return (
    <Image
      priority
      src={src}
      width={width}
      height={height}
      alt="Dscribe.me logo"
    />
  );
}
