import React from "react";
import layout from "@/styles/layout.module.scss";
import { motion } from "framer-motion";

export default function Layout({
  header,
  children,
}: {
  header: React.ReactNode;
  children: React.ReactNode;
  page?: string;
}) {
  const variants = {
    hidden: { opacity: 0, x: 0, y: 0 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0, x: 0, y: -100 },
  };

  return (
    <>
      {header}

      <motion.main
        key="layout"
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ type: "linear" }}
        className={layout.main}
      >
        {children}
      </motion.main>
    </>
  );
}
